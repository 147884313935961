<template>
  <div>
    <v-btn @click="newPost()" color="primary" dark>מודעה חדשה</v-btn>

    <v-dialog v-model="addDialog" max-width="90%">
      <v-card>
        <v-card-title>
          <span class="headline">מודעה חדשה</span>
        </v-card-title>
        <v-card-text>
          <v-text-field label="שם סוג המודע" v-model="postType.name"></v-text-field>
          <v-text-field label="icon" v-model="postType.icon"></v-text-field>
          <v-textarea label="מידע" v-model="postType.info"></v-textarea>

          <!-- <div v-for="field in fields" :key="field.id">
            <v-checkbox :label="field.name" v-model="selectedFields[field.id]"></v-checkbox>
          </div> -->
          <div class="d-flex flex-wrap">
            <v-card v-for="(field, index) in selectedFieldsExtended" :key="field.id + index"
              class="mx-1 my-2 elevation-9">
              <v-card-title>{{ field.typeName }}</v-card-title>
              <v-card-text>
                <v-checkbox label="להציג" v-model="field.selected"></v-checkbox>
                <!-- <v-text-field dense label="שם" v-model="field.name"></v-text-field> -->
                <v-select dense :items="fieldsWeights" label="משקל" v-model="field.weight"></v-select>
                <v-checkbox dense label="חובה" v-model="field.required"></v-checkbox>
                <v-checkbox dense label="סינון" v-model="field.filter"></v-checkbox>
                <v-checkbox dense label="סינון נוסף" v-model="field.extraFilter"></v-checkbox>
                <v-textarea dense label="מידע" v-model="field.info"></v-textarea>
                <v-text-field dense label="סדר" v-model="field.order"></v-text-field>
                <v-checkbox dense label="לכל השורה" v-model="field.fullLength"></v-checkbox>
                <v-checkbox dense label="בטבלה" v-model="field.inTable"></v-checkbox>
                <!-- <v-text-field :disabled="true" label="שם הסוג" v-model="field.typeName"></v-text-field> -->
                <v-text-field dense :disabled="true" label="סוג" v-model="field.type"></v-text-field>
                <v-text-field dense :disabled="true" label="ID" v-model="field.id"></v-text-field>
              </v-card-text>
            </v-card>
          </div>
          <v-btn color="light-green" :disabled="postType.name === ''" @click="saveOrUpdatePostType">שמור</v-btn>
          <v-btn dark color="red" v-if="postType.id" @click="deletePostType">הסר</v-btn>
          <v-btn @click="cencelEdit">בטל</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Список созданных типов постов -->
    <div v-for="(type) in postTypes" :key="type.id">
      <v-icon>mdi-{{ type.icon }}</v-icon> {{ type.name }} -
      <v-btn @click="editPostType(type)">ערוך</v-btn>
    </div>
  </div>
</template>

<script>
import fire from "@/main";

export default {
  name: "NewPostTypes",
  data() {
    return {
      postType: { name: "", fieldIds: [], icon: "" },
      fields: [],
      selectedFields: {},
      selectedFieldsExtended: {},
      selectedFieldsExtended_initiated: {},
      postTypes: [],
      addDialog: false,
      fieldsWeights: ["regular", "primary", "secondary", "price"],
    };
  },
  async mounted() {
    // Load available fields
    let ref = fire.database().ref('admin/fields/created_fields/addRecord');
    ref.on('value', (snapshot) => {
      let items = snapshot.val();
      let newState = [];
      let order = 0;
      this.selectedFieldsExtended = {};
      for (let item in items) {
        newState.push({
          id: item,
          ...items[item],
          order: order++
        });
        this.selectedFieldsExtended[item] = {}
        this.selectedFieldsExtended[item].id = item;
        this.selectedFieldsExtended[item].selected = false;
        this.selectedFieldsExtended[item].order = order;
        this.selectedFieldsExtended[item].weight = this.fieldsWeights[0];
        this.selectedFieldsExtended[item].required = false;
        this.selectedFieldsExtended[item].filter = false;
        this.selectedFieldsExtended[item].extraFilter = false;
        this.selectedFieldsExtended[item].info = "";
        // this.selectedFieldsExtended[item].name = "";
        this.selectedFieldsExtended[item].type = items[item].type || "ERROR";
        this.selectedFieldsExtended[item].typeName = items[item].name;
        this.selectedFieldsExtended[item].fullLength = false;
        this.selectedFieldsExtended[item].inTable = false;
      }
      this.fields = newState;
      this.selectedFieldsExtended_initiated = { ...this.selectedFieldsExtended };
      console.log("this.selectedFieldsExtended", this.selectedFieldsExtended);
      console.log("this.selectedFieldsExtended_initiated", this.selectedFieldsExtended_initiated);
    });

    // Load post types
    let typesRef = fire.database().ref('admin/postTypes');
    typesRef.on('value', (snapshot) => {
      let items = snapshot.val();
      let newState = [];
      for (let item in items) {
        newState.push({
          id: item,
          ...items[item]
        });
      }
      this.postTypes = newState;
    });
  },
  methods: {
    saveOrUpdatePostType() {
      const selectedIds = Object.keys(this.selectedFields).filter(id => this.selectedFields[id]);
      this.postType.fieldIds = selectedIds;
      this.postType.postTypeExtended = Object.values(this.selectedFieldsExtended).filter(field => field.selected);

      if (this.postType.id) {
        fire.database().ref(`admin/postTypes/${this.postType.id}`).update(this.postType);
      } else {
        const newRef = fire.database().ref('admin/postTypes').push(this.postType);
        this.postType.id = newRef.key;
        newRef.update({ id: newRef.key });
      }

      this.resetForm();
      this.addDialog = false;
    },
    editPostType(type) {
      this.postType = { ...type };
      this.selectedFields = {};
      if (!type.fieldIds) {
        type.fieldIds = [];
      }
      if (type.fieldIds) {
        type.fieldIds.forEach(id => {
          this.selectedFields[id] = true;
        });
        if (type.postTypeExtended) {
          type.postTypeExtended.forEach(field => {
            this.selectedFieldsExtended[field.id] = field;
          });
        } else {
          this.selectedFieldsExtended = { ...this.selectedFieldsExtended_initiated };
          type.fieldIds.forEach(id => {
            let type_tmp_obj = this.fields.find(field => field.id === id)
            if (!type_tmp_obj) {
              console.log("type_tmp_obj", type_tmp_obj);
              console.log("id", id);
              console.log("this.fields", this.fields);
            } else {
              // console.log("type_tmp_obj", type_tmp_obj);
              this.selectedFieldsExtended[id] = {
                id: id,
                selected: true,
                order: 0,
                weight: this.fieldsWeights[0],
                required: false,
                filter: false,
                extraFilter: false,
                info: "",
                // name: "",
                type: this.fields.find(field => field.id === id).type || "ERROR",
                typeName: this.fields.find(field => field.id === id).name,
                fullLength: false,
                inTable: false
              };
            }
          });
        }
      }
      if (type.postTypeExtended) {
        this.selectedFieldsExtended = { ...this.selectedFieldsExtended_initiated };
        type.postTypeExtended.forEach(field => {
          this.selectedFieldsExtended[field.id] = field;
        });
      }

      this.addDialog = true;
    },
    cencelEdit() {
      this.resetForm();
      this.addDialog = false;
    },
    deletePostType() {
      if (this.postType.id) {
        const isConfirmed = window.confirm('Вы действительно хотите удалить этот тип поста?');
        if (isConfirmed) {
          fire.database().ref(`admin/postTypes/${this.postType.id}`).remove();
          this.resetForm();
        }
      }
      this.resetForm();
      this.addDialog = false;
    },
    resetForm() {
      this.postType = { name: "", fieldIds: [] };
      this.selectedFields = {};
      this.selectedFieldsExtended = { ...this.selectedFieldsExtended_initiated };
    },
    fieldOrder(field, index) {
      if (field.order) {
        return field.order;
      } else {
        return index;
      }
    },
    newPost() {
      this.resetForm();
      this.addDialog = true;
    }
  }
}
</script>
